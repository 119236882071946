@import url("https://fonts.googleapis.com/css?family=Marck+Script|Ubuntu");

@tailwind base;
@tailwind components;
@tailwind utilities;

#root { justify-content: space-around; align-items: center; }


#header { top: 0; }

#footer { bottom: 0; }

#header, #footer { display: flex; justify-content: center; width: 100%; position: absolute; z-index: 1; }

#header > nav > div { display: flex; flex: 1; align-items: center; justify-content: center; border: none;}

#header > nav > :nth-child(1) { justify-content: flex-start; padding-left: 8px; }

#header > nav > :last-child { justify-content: flex-end; padding-right: 8px;}

.sr-layout { display: flex; flex-direction: column; width: 100%; height: 100%; overflow-y: auto; box-sizing: border-box; margin: 0; padding: 0; }



/* home page child */

.home-chart{ width: 100%; min-height: 100%; }


.header { width: 100%; aspect-ratio: 2.5 / 1;  background-color: rgba(0, 0, 0, 0); }

.social { display: flex; width: 100%; overflow-x: scroll; align-items: center; justify-content: center; }
.social > .fab { font-size: 28px; color: #576574; }


.profile { box-shadow: 0px 1px 4px 1px #888888a4; position: relative; left: calc(50% - 70px); top: -35px; width: 140px; height: 140px; border-radius: 50%; }
.profile img { width: 100%; height: 100%; border-radius: 50%; }


.content { margin-top: -70px; text-align: center; padding: 0 30px; color: #2d3436; }
.content .title { margin-bottom: 0; font-family: "Marck Script", cursive; font-size: 28px; }
.content span { font-size: 12px; letter-spacing: 1.5px; }
.content p { margin: 20px 0; font-size: 18px; font-family: "Marck Script", cursive; line-height: 1.6; color: #b0b0b0; }


#message-field { display: flex; flex-direction: column; flex: auto; overflow-y: auto; }

#message-field > #wellcome { display: flex; align-items: center; }

#message-field > .message { display: flex; align-items: center; padding: 8px; border-radius: 4px; }

#message-field > .user { background-color: #3b82f6; margin: 8px 8px 0 36px; color: white; }

#message-field > .admin { background-color: #00b341; margin: 8px 36px 0 8px; color: white; }

form#visitor { display: flex; align-items: center; border-radius: 4px; margin: 8px;}

form#visitor > input { border: none; outline: none; display: flex; width: 100%; padding: 8px; border-radius: 8px 0 0 8px; }

form#visitor > #send-button { display: flex; flex: auto; padding: 8px; }


.hide { display: none!important; }

a, .icon { text-decoration: none; color: #55575b; padding: 8px; font-size: 24px; }

/* Bg variables */
.bg1 { background-color: #262C33; }
.bg2 { background-color: #8FA7BF; }
.bg3 { background-color: #566473; }
.bg4 { background-color: #606F80; }
.bg5 { background-color: #434E59; }



/* Genel scrollbar ayarları */
::-webkit-scrollbar {
    width: 2px; /* Dikey kaydırıcı genişliği */
    height: 2px; /* Yatay kaydırıcı yüksekliği */
  }
  
  /* Kaydırıcının arka planı */
  ::-webkit-scrollbar-track {
    background: none; /* Arka plan rengi */
  }
  
  /* Kaydırıcı */
  ::-webkit-scrollbar-thumb {
    background: #888; /* Kaydırıcı rengi */
  }
  
  /* Kaydırıcı üzerine gelindiğinde */
  ::-webkit-scrollbar-thumb:hover {
    background: #555; /* Hover rengi */
  }
  
  /* Yatay kaydırıcı için ayarlar */
  ::-webkit-scrollbar-thumb:horizontal {
    background: #888; /* Yatay kaydırıcı rengi */
  }
  
  /* Yatay kaydırıcı üzerine gelindiğinde */
  ::-webkit-scrollbar-thumb:horizontal:hover {
    background: #555; /* Yatay hover rengi */
  }